<!--<template>-->
    <!--<div class='menu-wrapper'>-->

        <!--&lt;!&ndash;<template v-for="(item,i) in routes">&ndash;&gt;-->
        <!--&lt;!&ndash;<template v-if="item.children">&ndash;&gt;-->
        <!--&lt;!&ndash;<el-submenu :index="item.path+'/'+item.children[0].path">&ndash;&gt;-->
        <!--&lt;!&ndash;<template slot="title"><i :class="item.icon ? item.icon : ''"></i>{{item.name}}</template>&ndash;&gt;-->


        <!--&lt;!&ndash;<template v-for="(subItem,i1) in item.children">&ndash;&gt;-->
        <!--&lt;!&ndash;<template v-if="subItem.children">&ndash;&gt;-->
        <!--&lt;!&ndash;<el-submenu :index="item.path + '/' + subItem.path + '/' + subItem.children[0].path">&ndash;&gt;-->
        <!--&lt;!&ndash;<template slot="title"><i :class="subItem.icon ? subItem.icon : ''"></i>{{subItem.name}}</template>&ndash;&gt;-->

        <!--&lt;!&ndash;<template v-if="subItem.children">&ndash;&gt;-->
        <!--&lt;!&ndash;<el-menu-item v-for="(subItemItem,i2) in subItem.subs" :key="i2" :index="item.path + '/' + subItem.path + '/' + subItemItem.path">&ndash;&gt;-->
        <!--&lt;!&ndash;<template slot="title"><i :class="subItemItem.icon ? subItemItem.icon : ''"></i>{{subItemItem.name}}</template>&ndash;&gt;-->
        <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->
        <!--&lt;!&ndash;</template>&ndash;&gt;-->

        <!--&lt;!&ndash;</el-submenu>&ndash;&gt;-->
        <!--&lt;!&ndash;</template>&ndash;&gt;-->

        <!--&lt;!&ndash;<template v-else>&ndash;&gt;-->
        <!--&lt;!&ndash;<el-menu-item :index="item.path + '/' + subItem.path">&ndash;&gt;-->
        <!--&lt;!&ndash;<i :class="subItem.icon ? subItem.icon : ''"></i>{{ subItem.name }}&ndash;&gt;-->
        <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->
        <!--&lt;!&ndash;</template>&ndash;&gt;-->
        <!--&lt;!&ndash;</template>&ndash;&gt;-->

        <!--&lt;!&ndash;</el-submenu>&ndash;&gt;-->
        <!--&lt;!&ndash;</template>&ndash;&gt;-->
        <!--&lt;!&ndash;<template v-else>&ndash;&gt;-->
        <!--&lt;!&ndash;<el-menu-item :index="item.path">&ndash;&gt;-->
        <!--&lt;!&ndash;<template slot="title"><i :class="item.icon ? item.icon : ''"></i>{{item.name}}</template>&ndash;&gt;-->
        <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->
        <!--&lt;!&ndash;</template>&ndash;&gt;-->
        <!--&lt;!&ndash;</template>&ndash;&gt;-->

        <!--<template v-for="(item,i) in routes">-->
            <!--<template v-if="item.children">-->
                <!--<el-submenu :index="item.path+'/'+item.children[0].path">-->
                    <!--<template slot="title"><i :class="item.icon ? item.icon : ''"></i>{{item.path}}-{{item.name}}</template>-->

                    <!--<template v-for="subChild in item.children">-->
                        <!--<sidebar-item :routes='[subChild]'></sidebar-item>-->
                    <!--</template>-->

                <!--</el-submenu>-->
            <!--</template>-->
            <!--<template v-else>-->
                <!--<el-menu-item :index="item.path">-->
                    <!--<template slot="title"><i :class="item.icon ? item.icon : ''"></i>{{item.path}}-{{item.name}}</template>-->
                <!--</el-menu-item>-->
            <!--</template>-->
        <!--</template>-->


    <!--</div>-->
<!--</template>-->

<!--<script>-->
    <!--export default {-->
        <!--name: 'SidebarItem',-->
        <!--props: {-->
            <!--routes: {-->
                <!--type: Array-->
            <!--}-->
        <!--}-->
    <!--}-->
<!--</script>-->

<template>
  <div
    @click="onChangeTab(item.redirect)"
    v-if="isRresh"
    v-show="item.roleType.indexOf(userType) !== -1"
  >
    <!-- v-if="!item.hidden&&item.noDropdown&&!item.children[0].children" -->
    <!-- :style="name == item.redirect?'background: #1241de !important; ':''" -->

    <router-link :to="item.path + '/' + item.children[0].path">
      <el-menu-item class="submenu-title-noDropdown" :index="item.redirect">
        <!-- <icon-svg v-if='item.icon'
                  :icon-class="item.icon"></icon-svg> -->
        <img :src="item.icon" />
        <div>{{ item.name }}</div>
        <!-- <div>{{$route.path == item.redirect?'1':2}}</div> -->

        <!-- <div class="icon-item">

          <icon-svg v-if='item.icon'
                    :icon-class="item.icon"></icon-svg>
          <div>{{item.name}}123</div>

        </div> -->
      </el-menu-item>
    </router-link>

    <!-- <el-submenu class="nest-menu"
                :index="item.path"
                v-else-if="!item.noDropdown&&!item.hidden">
      <template slot="title">
        <icon-svg v-if='item.icon'
                  :icon-class="item.icon"></icon-svg>
        <span v-if='item.name'
              slot="title">{{item.name}}456</span>
      </template>
      <template v-for="child in item.children"
                v-if='!child.hidden'>

        <sidebar-item :key="child.path"
                      v-if='child.children&&child.children.length>0'
                      :item='child'> </sidebar-item>

        <router-link :key="child.path"
                     v-else
                     :to="item.path+'/'+child.path">
          <el-menu-item :index="item.path+'/'+child.path">
            <icon-svg v-if='child.icon'
                      :icon-class="child.icon"></icon-svg>
            <span slot="title">{{child.name}}</span>
          </el-menu-item>
        </router-link>

      </template>

    </el-submenu> -->
  </div>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    item: {},
  },
  data() {
    return {
      name: "",
      userType: "",
      isRresh: true,
    };
  },
  created() {
    this.name = this.$route.path;
    this.userType = JSON.parse(window.localStorage.getItem("uerInfo")).userType;
    console.log(this.$route.path, this.item.redirect);
  },
  watch: {},
  methods: {
    onChangeTab(e) {
      // this.name = e;
      // this.$forceUpdate();
      // console.log(this.name, e);
      // this.$router.go(0)
      // window.location.reload();
      // this.$forceUpdate()
      // this.isRresh = false
      // this.$nextTick(() => {
      //   this.isRresh = true
      // })
      // setTimeout(() => {
      //   this.isRresh = true
      // }, 10);
    },
  },
};
</script>

<style>
.el-menu-item {
  height: 100px !important;
  color: #fff !important;
  text-align: center;
  line-height: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background: #3064ff !important;
}

.el-menu-item img {
  width: 24px;
  height: 24px;
  margin-bottom: 14px;
}

.container-left {
  background: #3064ff !important;
}

.container-left .submenu-title-noDropdown:hover,
.container-left .el-submenu__title:hover {
  background: #1241de !important;
}

a:hover {
  text-decoration: none;
}
</style>
