import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import * as echarts from 'echarts';

import preventReClick from './api/preventReClick'


import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    'zh': require('./components/language/zh'),
    'en': require('./components/language/en')
  }
})



Vue.prototype.$axios = axios



Vue.use(preventReClick);


Vue.prototype.$echarts = echarts

Vue.use(ElementUI);

Vue.config.productionTip = false


new Vue({
  router,
  store,
  i18n,
  components: {
    App
  },
  render: h => h(App),
}).$mount('#app')
