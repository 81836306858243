import Home from "../views/teacher/index.vue";



const main2 = r =>
  require.ensure([], () => r(require("../views/teacher/main2.vue")), "home");

// 培养计划

const grow = r =>
  require.ensure([], () => r(require("../views/teacher/grow.vue")), "home");
// const resource = r =>
//   require.ensure([], () => r(require("../views/teacher/resource.vue")), "home");


const statistics = r =>
  require.ensure([], () => r(require("../views/teacher/statistics.vue")), "home");


const users = r =>
  require.ensure([], () => r(require("../views/teacher/users.vue")), "home");

const lessons = r =>
  require.ensure([], () => r(require("../views/teacher/lessons.vue")), "home");



const work = r =>
  require.ensure([], () => r(require("../views/teacher/work.vue")), "home");


const mine = r =>
  require.ensure([], () => r(require("../views/teacher/mine.vue")), "home");



const questions = r =>
  require.ensure([], () => r(require("../views/teacher/questions.vue")), "home");



const taolun = r =>
  require.ensure([], () => r(require("../views/teacher/taolun.vue")), "home");


const first = r =>
  require.ensure([], () => r(require("../views/teacher/first.vue")), "home");



const chengji = r =>
  require.ensure([], () => r(require("../views/teacher/chengji.vue")), "home");



const gonggao = r =>
  require.ensure([], () => r(require("../views/teacher/gonggao.vue")), "home");

// const statisticsDetail = r =>
//   require.ensure([], () => r(require("../views/teacher/statisticsDetail.vue")), "home");


const statisticsStudents = r =>
  require.ensure([], () => r(require("../views/teacher/statisticsStudents.vue")), "home");


const chengjiStudents = r =>
  require.ensure([], () => r(require("../views/teacher/chengjiStudents.vue")), "home");


const taolunList = r =>
  require.ensure([], () => r(require("../views/teacher/taolunList.vue")), "home");

const routes = [
  // 首页
  {
    path: "/",
    redirect: "/lesson_detail"
  },
  {
    path: "/loginAll",
    name: "loginAll",
    component: () => import("../views/loginAll.vue"),
  },

  {
    path: "/MapContainer",
    name: "MapContainer",
    component: () => import("../views/MapContainer.vue"),
  },

  {
    path: "/lesson_video",
    name: "lesson_video",
    component: () => import("../views/student/lesson_video.vue"),
  },


  {
    path: "/lesson_work",
    name: "lesson_work",
    component: () => import("../views/student/lesson_work.vue"),
  },
  {
    path: "/lesson_detail",
    name: "lesson_detail",
    component: () => import("../views/student/lesson_detail.vue"),
  },
  {
    path: "/lesson_detail2",
    name: "lesson_detail2",
    component: () => import("../views/student/lesson_detail2.vue"),
  },
  {
    path: "/lesson_detail3",
    name: "lesson_detail3",
    component: () => import("../views/student/lesson_detail3.vue"),
  },
  {
    path: "/lesson_detail4",
    name: "lesson_detail4",
    component: () => import("../views/student/lesson_detail4.vue"),
  },
  {
    path: "/lesson_detail5",
    name: "lesson_detail5",
    component: () => import("../views/student/lesson_detail5.vue"),
  },
  {
    path: "/lesson_detail6",
    name: "lesson_detail6",
    component: () => import("../views/student/lesson_detail6.vue"),
  },
  {
    path: "/lesson_detail7",
    name: "lesson_detail7",
    component: () => import("../views/student/lesson_detail7.vue"),
  },
  {
    path: "/mine",
    name: "mine",
    component: () => import("../views/student/mine.vue"),
  },


  // {
  //   path: "/",
  //   component: Home,
  //   redirect: "/readme",
  //   name: "首页",
  //   hidden: true
  // },



  // {
  //   path: "/readme",
  //   component: Home,
  //   redirect: "/readme/resource",
  //   icon: require("../assets/tabs/icon2.png"),
  //   name: "资源库",
  //   noDropdown: true,
  //   roleType: [2],
  //   children: [
  //     {
  //       path: "resource",

  //       component: resource
  //     }
  //   ]
  // },





  {
    path: "/readme",
    component: Home,
    redirect: "/readme/first",
    icon: require("../assets/tabs/icon2.png"),
    name: "首页",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "first",
        component: first
      }
    ]
  },



  {
    path: "/readme",
    component: Home,
    redirect: "/readme/statistics",
    icon: require("../assets/tabs/icon2.png"),
    name: "统计与分析",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "statistics",

        component: statistics
      },
      {
        path: "statisticsStudents",
        name: "学习记录",

        component: statisticsStudents
      }
    ]
  },


  {
    path: "/readme",
    component: Home,
    redirect: "/readme/main2",
    icon: require("../assets/tabs/icon1.png"),
    name: "资源管理",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "main2",
        component: main2
      }
    ]
  },

  // 培养计划
  // {
  //   path: "/readme",
  //   component: Home,
  //   redirect: "/readme/grow",
  //   icon: require("../assets/tabs/icon1.png"),
  //   name: "培养计划",
  //   noDropdown: true,
  //   roleType: [2],
  //   children: [
  //     {
  //       path: "grow",
  //       component: grow
  //     }
  //   ]
  // },
  {
    path: "/readme",
    component: Home,
    redirect: "/readme/users",
    icon: require("../assets/tabs/icon1.png"),
    name: "用户管理",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "users",

        component: users
      }
    ]
  },

  {
    path: "/readme",
    component: Home,
    redirect: "/readme/lessons",
    icon: require("../assets/tabs/icon2.png"),
    name: "作业管理",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "lessons",

        component: lessons
      }
    ]
  },



  {
    path: "/readme",
    component: Home,
    redirect: "/readme/work",
    icon: require("../assets/tabs/icon1.png"),
    name: "作业管理",
    noDropdown: true,
    roleType: [3],
    children: [
      {
        path: "work",

        component: work
      }
    ]
  },


  {
    path: "/readme",
    component: Home,
    redirect: "/readme/questions",
    icon: require("../assets/tabs/icon2.png"),

    name: "思考题管理",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "questions",

        component: questions
      },
      {
        path: "chengjiStudents",
        name: "学员参与名单",

        component: chengjiStudents
      }
    ]
  },



  {
    path: "/readme",
    component: Home,
    redirect: "/readme/taolun",
    icon: require("../assets/tabs/icon2.png"),

    name: "讨论区管理",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "taolun",

        component: taolun
      },
      {
        path: "taolunList",
        name: "学员参与名单",

        component: taolunList
      }
    ]
  },



  {
    path: "/readme",
    component: Home,
    redirect: "/readme/chengji",
    icon: require("../assets/tabs/icon2.png"),

    name: "成绩管理",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "chengji",

        component: chengji
      }
    ]
  },




  {
    path: "/readme",
    component: Home,
    redirect: "/readme/gonggao",
    icon: require("../assets/tabs/icon1.png"),
    name: "公告管理",
    noDropdown: true,
    roleType: [2],
    children: [
      {
        path: "gonggao",

        component: gonggao
      }
    ]
  },


  {
    path: "/readme",
    component: Home,
    redirect: "/readme/mine",
    icon: require("../assets/tabs/icon1.png"),
    name: "个人中心",
    noDropdown: true,
    roleType: [2, 3],
    children: [
      {
        path: "mine",

        component: mine
      }
    ]
  },







];
export default routes;         