
module.exports = {
  language: {
    name: 'English'
  },
  user: {
    login: '登录',
    register: '注册',
    loginUsername: '请输入您的用户名',
    loginPassword: '请输入您的密码',
  }
}
