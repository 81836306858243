import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    avatar: window.localStorage.getItem("avatar") || '',
    nickName: window.localStorage.getItem("nickName") || '',
    token: window.localStorage.getItem("token") || '',
    uerInfo: window.localStorage.getItem("uerInfo") || '',
    activePath: '',
  },
  mutations: {
    changeAvatar(state, playload) {
      window.localStorage.setItem('avatar', playload)
      state.avatar = playload
    },
    changeNickName(state, playload) {
      window.localStorage.setItem('nickName', playload)
      state.nickName = playload
    },
    changeToken(state, playload) {
      window.localStorage.setItem('token', playload)
      state.token = playload
    },
    changeUerInfo(state, playload) {
      window.localStorage.setItem('uerInfo', playload)
      state.uerInfo = playload
    },
    changActivePath(state, activePath) {
      state.activePath = activePath
    }
  },
  actions: {
    saveAvatar(context, playload) {
      context.commit('changeAvatar', playload)
    },
    saveNickName(context, playload) {
      context.commit('changeNickName', playload)
    },
    saveToken(context, playload) {
      context.commit('changeToken', playload)
    },
    saveUerInfo(context, playload) {
      context.commit('changeUerInfo', playload)
    },
    changActivePathAsync(context, activePath) {
      context.commit('changActivePath', activePath)
    },
  },
})
