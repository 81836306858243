
module.exports = {
  language: {
    name: '中文'
  },
  user: {
    login: 'login',
    register: 'register',
    loginUsername: 'please input your name',
    loginPassword: 'please input your password',

  }
}