import Vue from "vue";
import VueRouter from "vue-router";

import routerStudent from './student';

Vue.use(VueRouter);
const router = new VueRouter({
  routes: routerStudent,
});

//路由守卫

// if (!Boolean(ISMOBILE())) {
// router.beforeEach((to, from, next) => {
//   if (to.path == "/index_pc" || to.path == "/index_login" || to.path == '/healthy_life/eat' || to.path == '/healthy_life/problem' || to.path == '/healthy_life/sports' || to.path == '/home_education' || to.path == '/happy_family' || to.path == "/puberty_FQA") {
//     next();
//   } else {
//     if (window.localStorage.getItem('token')) {
//       next();
//     } else {
//       next("/index_login");
//     }
//   }
// });
// }
export default router;


